import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { v4 } from "uuid"
import {
  Section,
  SectionTitle,
  SectionLead,
  MarkdownContent,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/CarportMap"
import LocationStyle from "../components/LocationStyle"
// import StateSale from "../components/StateSale"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const ListAreas = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  ${BreakpointUp.sm`
        column-count: 3;
    `}
  ${BreakpointUp.lg`
        column-count: 4;
    `}
`
const ListItem = styled.li`
  padding-left: 20px;
  vertical-align: middle;
  margin: 0;
  line-height: 26px;
  + li {
    margin-top: 10px;
  }
  &:before {
    content: url(${ListArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const LoadMore = styled.button`
  display:block;
  margin:0 auto;
`

const State = ({ data, location, pageContext: { category },  }) => {
  const [ limit, Setlimit ] = useState(20);
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  
  const pageData = data.contentfulState
  const productCategory = category
  const relatedProductsData = []

  data.allContentfulProduct.edges.map((product) => {
     if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
       relatedProductsData.push(product.node)
     } 
  })
 

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Layout location={location}>
      <Seo
        title={`𝐌𝐞𝐭𝐚𝐥 𝐂𝐚𝐫𝐩𝐨𝐫𝐭𝐬 ${pageData.name} - Buy Highly Durable Carports And Kits`}
        description={`Order highly durable steel carports in ${pageData.name}. Coast to Coast Carports is the #1 manufacturer offering custom metal carports and kits online.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0 overflow-initial"
      >
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Carports ${pageData.name}`}</h1>
                <p>
                  Looking for the best storage option to keep your vehicles and
                  other valuable items and equipment protected from the
                  sometimes extreme weather challenges of {pageData.name}? Check
                  out our high-quality metal carports and buildings. At Coast To
                  Coast Carports, we craft and install durable metal carports of
                  all sizes and shapes for folks all across{" "}
                  {pageData.abbreviation}!
                </p>
                <p>Metal Carports in {pageData.name} are highly durable structures, available in a wide range of style, including one-car carports, two-car carports, three-car carports, RV carports, and more. These steel carports can withstand heavy snow, wind, and rain. All our carports are engineered in such a way that they can stand up to any inclement weather condition. The galvanized steel panels strengthen the metal structure and make them last for generations. You can utilize these carports for parking your priced vehicles and storing your garden equipment, or you can even use them as a poolside cabana. Call our building professionals today at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to order your metal carport in {pageData.name} they will help you design, customize and realize the structure you need.</p>
                <h2>
                  Advantages Of Metal Carports In {pageData.name},{" "}
                  {pageData.abbreviation}
                </h2>
                <h3>Premium {pageData.name} Metal Carports</h3>
                <p>
                  Looking for the best storage option to keep your vehicles and
                  other valuable items and equipment safe from {pageData.name}’s
                  extreme weather swings? Take a look at our high-quality metal
                  carports and buildings. At Coast To Coast Carports, we are
                  specialists in crafting and installing durable storage
                  buildings of all sizes and shapes. Our metal carports are both
                  sturdy and attractive, providing you maximum protection for
                  keeping all your vehicles and equipment safe and secure.
                </p>

                <h3>Great Custom Metal Carports and Metal Buildings</h3>
                <p>
                  Not only are our carports made with premium quality metal, but
                  they’re customizable, too. Coast to Coast Carports offers its
                  customers a full range of options when it comes to designing
                  their custom metal carport in {pageData.name}. From the
                  dimensions to the roof style to the color options and more,
                  just let us know your specifications, and we’ll take it from
                  there! Whether you need an affordable metal carport for a
                  single vehicle, or need a commercial-sized building for other
                  applications, we can build your {pageData.name} carport to
                  meet all your specific needs.
                </p>

                <h3>Choose from a Variety of Designs</h3>
                <p>
                  When you choose Coast To Coast Carports, Inc. for your metal
                  carport needs in {pageData.name}, you also get to choose from
                  a variety of size, style, and design options. There’s no need
                  to settle for a big box store with poor selection, high
                  prices, and lesser quality carports. Coast To Coast Carports
                  offers the best metal carports with the best prices in{" "}
                  {pageData.name}, and we do more than just carports, too. Need
                  a garage, shed, stable, barn, or warehouse? We’ve got you
                  covered! We also offer an upgrade to 12GA tubing that comes
                  with a rust-through warranty that’s good for 20 years.
                </p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
              {/* {(pageData.abbreviation === "AR" || pageData.abbreviation === "IL" || pageData.abbreviation === "NM" || pageData.abbreviation === "CO" || pageData.abbreviation === "ID") && <StateSale state={pageData.abbreviation} /> } */}
                <PrimaryForm states={data.allContentfulState.edges} location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data={pageData.locationsForMainBuildingCategories} />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {pageData.name}’s Metal Carports</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle state={pageData.name} />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="30px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle textAlign="left" maxWidth="910px">
            Roof Style Options For Your {pageData.name} Metal Carport
          </SectionTitle>
          <MarkdownContent maxWidth="910px" mb="30px" textAlign="left">
            <p>
              Choose from the following three styles of roof for your{" "}
              {pageData.name} metal carport from Coast To Coast Carports:
            </p>
            <h3>Regular (Rounded Corners) Style – GOOD</h3>
            <p>
              This roof style is the most economical. The corrugated ridges run
              from end to end. This roof style is great if you live in an area
              that doesn’t typically experience heavy wind or rain. A regular
              roof is perfect for protecting your vehicles and other items from
              excessive UV exposure.
            </p>

            <h3>A-Frame Horizontal – BETTER</h3>
            <p>
              Also known as a boxed eave roof, this is an A-frame style with
              roof panels that are installed horizontally. This is a stronger
              design than the regular roof, and it performs better for areas
              that do receive some stronger winds, but not excessive
              precipitation.
            </p>

            <h3>A-Frame Vertical – BEST</h3>
            <p>
              This is an A-frame style with panels that are installed
              vertically, so rain and snow can glide down and away from your
              building more easily. The vertical roof also comes with
              hat-channel pieces and more reinforcement, and is our strongest
              roof design option. If you live in area that experiences heavy
              rain or snowfall, or if your building is longer than 36’, we
              highly recommend that you go with a vertical roof.
            </p>
            <p>
              Because {pageData.name} experiences a range of weather conditions,
              we recommend the extra durability and strength of a vertical roof
              for all of our {pageData.name} metal building customers. You can
              also choose from either front or side-entry options for your
              carport, garage, or other metal building, depending on the layout
              of your install site and on your convenience and preferences.
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionEditor>
            <SectionEditorLeft>
              <h2>
                Metal Carports {pageData.name} – Carports Installation{" "}
                {pageData.abbreviation}
              </h2>
            </SectionEditorLeft>
            <SectionEditorRight>
              <p>
                With Coast To Coast Carports, you don’t have to worry with the
                hassle of figuring out how to get the building to your site, or
                with the headache of trying to put it up yourself. We are happy
                to provide full delivery and installation services! All you need
                to do is ensure that your installation site is level before we
                arrive with your building. Let the experts at Coast To Coast
                Carports take care of the rest!
              </p>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>
                Why Choose Coast to Coast for Metal Carports in {pageData.name}:
              </h2>
              <p>
                From our local manufacturing facilities in AR, TX, ID, and OR,
                Coast To Coast Carports stands ready to take care of all your{" "}
                {pageData.name} carport needs. We can deliver and install your
                metal carport anywhere in {pageData.abbreviation}, and our
                expert team will do the job right for you the first time, and
                every time! Coast To Coast Carports can construct and install
                everything from carports to RV covers to sheds to garages to
                entire storage units. We’ve been in business since 2001, helping
                customers in {pageData.name} and across the country to get the
                prefab, custom-engineered metal structure to meet any
                residential, commercial, or agricultural needs. Reach out to us
                at Coast To Coast Carports today, and let us show you why we’re
                the best in {pageData.abbreviation}!
              </p>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="0">Areas We Serve In {pageData.name}</SectionTitle>
          <SectionLead mb="40px" maxWidth="900px">
            We cover all locations throughout the state of {pageData.name},
            including:
          </SectionLead>
          <ListAreas>
          {pageData.cities.slice(0, limit).map(item => {            
              return (
                <ListItem key={v4()}>
                  {item.content}
                </ListItem>
              )
            })}
          </ListAreas>
          {limit !== pageData.cities.length && 
          
            <LoadMore onClick={()=> Setlimit(pageData.cities.length)}><PrimaryButton text="Load More" /></LoadMore>
          }
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default State

export const pageQuery = graphql`
  query CarportsStateLandingQuery($id: String!, $name: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      cities {
        content
      }
      locationsForMainBuildingCategories {
        metalCarportsAddress
        metalCarportContactNumber
        metalCarportLocationLink
        metalCarportMapEmbedLink {
          metalCarportMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationsForMainBuildingCategories {
            metalCarportsAddress
            metalCarportContactNumber
            metalCarportLocationLink
            metalCarportMapEmbedLink {
              metalCarportMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: {installationStates: {elemMatch: {name: {glob: $name}}}}
    ) {
      edges {
        node {
          id
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        
        }
      }
    }
  }
`
